.App {
  text-align: center;
}

html{
  scroll-behavior: smooth;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.ul.list-none li::before {
  content: "";
  position: absolute;
  top: 8px;
  left: -8px;
  width: 1px;
  height: calc(100% - 12px);
  background-color: #E2E8F0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.random{
  background-image: url('../public/png/jathinbg.jpg');
  background-size: cover;
  background-position: var(--center-max-w);
}

.background-img
{
  background: url(https://images.pexels.com/photos/10669542/pexels-photo-10669542.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load);
    background-size: cover;
    background-blend-mode: multiply;
    background-attachment: fixed;
    background-position: center;
}

.primary-color {
  background: linear-gradient(to right, #ff0090 30%, #ff7d00 70%);
  background-clip: text;
  color: transparent;
}
